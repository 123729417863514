<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      You are given a circle with center at O and radius=OR. PR is a tangent to the circle drawn from point P and touches the circle at point R.<br>
      In a circle, a tangent is perpendicular to the radius of the circle at the point of contact.<br>
      Thus, triangle ORP is a right-angled triangle with &angle;ORP = \(90^o\). <br>
      You can use Pythagoras theorem to relate the three lengths OR, PR and OP as follows:
      $$OP^2 = PR^2 + OR^2$$
      or
      $$PR^2 = OP^2 - OR^2$$
      or
      $$PR = \sqrt{OP^2 - OR^2}$$
      <br>
    </p>
    <h5 ref="pg">
      MagicGraph | Finding the Length of a Tangent
    </h5>
    <p class="big mt-2">
      In this MagicGraph, you will learn about
      finding the length of a tangent of a circle using Pythagoras theorem.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TangentLength',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Length of a Tangent';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Tangent Length',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about length of a tangent of a circle.'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
