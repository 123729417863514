// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeErase,
    placeStartOver
} from '../Utils.js';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.line.highlight=false;
  JXG.Options.polygon.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 12, 14, -8],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =3;
  brd1.options.layer['line'] =4;
  brd1.options.layer['point'] =5;
  brd1.options.layer['glider'] =6;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Length of a Tangent', '');
  placeLogo(brd1);
  var reload = placeShuffle(brd1, 'left');
  hoverMe(brd1, reload, 'Shuffle', 0, 0);
  //var test = placeTest(brd1);
  var redo = placeErase(brd1, 'right');
  hoverMe(brd1, redo, 'Start Over', 0, 0);
  var centerx = 0;
  var centery = 0;
  var i =0;
  var rad = [2, 3, 4, 5, 5];
  var XList=[7, 6, 8, 7, 8];
  reload.on('down', function(){
    if(i<4){
    i +=1;
    }
    else{
      i=0;
    }
  });
  redo.on('down', function(){
    i=0;
  });
  let switchStatus = true;

  var tx = 10;
  var ty = 6.5;

  var sizeOk = 1;
  var inpX = tx + 1.8;
  var inpY =ty + 1.5;

  var inpX2 = tx ;
  var inpY2 =ty-2.5;

  var x0_tape = 5;
  var y0_tape = 1;
  var size_tape = 2;

  //Radius
  //var radius = brd1.create('slider',[[-5.5,1],[-5.5,5],[1,3,5]], {snapWidth:1 ,face:'>', size:8, strokeWidth:2, name:'&nbsp; R', strokeColor:'black', fillColor: '#5B43FF',  baseline: { strokeColor: '#5B43FF'}, highline: { strokeColor: '#5B43FF'}, postLabel: '', label:{offset:[0,25], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//  var radius = placeSlider(brd1, 0, 8, 0, 3, 5, 5, 'R');
  //Circle
  var pt0 = brd1.create('point', [centerx, centery],{fixed:true, face:'o' , name:'O', size:3, strokeColor: 'black', fillColor: 'black', visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  pt0.label.setAttribute({offset:[0, -20]});

  var pt1 = brd1.create('point', [()=>centerx+rad[i], centery],{fixed:true, face:'o' , name:'O', size:3, strokeColor: '#5B43FF', fillColor: '#5B43FF', visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

  var c1 = brd1.create('circle', [pt0, pt1], {strokeColor: '#5B43FF', fillColor:'white', fillOpacity:0.5, strokeWidth: 4, fixed:true, highlightFillOpacity:0.2});
  //OP
  var p_P = brd1.create('point', [()=>XList[i],()=>0.0], {name: 'P', face:'circle', size:3, snapToGrid:true, strokeColor: 'black', fillColor: 'black', label:{offset: [-5, 20], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var seg_op = brd1.create('segment', [p_P, pt0], {fixed: true, strokeColor: 'red', strokeWidth: 3, dash:0});
  p_P.label.setAttribute({offset:[0, -20]});

  //Tangents
  var p_M = brd1.create('midpoint', [seg_op], {visible: false, name: 'M', face:'circle', size:2, label:{offset: [-5, 20], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var c2 = brd1.create('circle', [p_M, p_P], {center:{visible:false}, visible: false, strokeColor: '#5B43FF', strokeWidth: 4, fixed:true});

  //Intersection
  var p_Q = brd1.create('intersection', [c1, c2, 0], {visible:false, face:'o' , name:'Q', size:3, strokeColor: 'black', fillColor: 'black', fixed:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var p_R = brd1.create('intersection', [c1, c2, 1], {face:'o' , name:'R', size:3, strokeColor: 'black', fillColor: 'black', fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  p_R.label.setAttribute({offset:[0, 20]});
  brd1.create('polygon', [p_R,p_P,pt0], {fillColor:'red', fillOpacity:0.0,  borders:{visible:false}});
  //PR and PQ
  var seg_pr = brd1.create('segment', [p_P, p_Q], {visible:false, fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});
  var seg_pq = brd1.create('segment', [p_P, p_R], {fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});
  var seg_qr = brd1.create('segment', [p_Q, p_R], {visible:false, fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});

  var p_S = brd1.create('intersection', [seg_op, seg_qr, 0], {face:'o' , name:'S', size:3, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

  var seg_os = brd1.create('segment', [pt0, p_S], {visible: false, fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});

  //Radius line
  var seg_radius1 = brd1.create('segment', [pt0, p_Q], {fixed: true, strokeColor: 'blue', strokeWidth: 3, dash: 0, visible: false});
  var seg_radius2 = brd1.create('segment', [pt0, p_R], {fixed: true, strokeColor: 'red', strokeWidth: 3, dash: 0, visible: true});

  //Tape
  /*
  var tape = brd1.create('tapemeasure', [[0,-5], [5,-5]], {name:'',
                                                           strokeColor: 'orange',
                                                           strokeWidth: 5,
                                                           highlightStrokeColor: 'orange',
                                                           withTicks: true,
                                                           withLabel: true,
                                                          precision: 2,

                                                          point1: {
                                                              visible: 'inherit',
                                                              strokeColor: 'black',
                                                              fillColor: 'orange',
                                                              fillOpacity: 1.0,
                                                              highlightFillOpacity: 0.1,
                                                              size:5,
                                                              snapToPoints: true,
                                                              attractorUnit: 'screen',
                                                              attractorDistance: 20,
                                                              showInfobox: false,
                                                              withLabel: false,
                                                              name: '',
                                                              highlight:false
                                                          },

                                                          point2: {
                                                              visible: 'inherit',
                                                              strokeColor: 'black',
                                                              fillColor: 'orange',
                                                              fillOpacity: 1.0,
                                                              highlightFillOpacity: 0.1,
                                                              size:5,
                                                              snapToPoints: true,
                                                              attractorUnit: 'screen',
                                                              attractorDistance: 20,
                                                              showInfobox: false,
                                                              withLabel: false,
                                                              name: '',
                                                              highlight:false
                                                          },

                                                          ticks: {
                                                              drawLabels: false,
                                                              drawZero: true,
                                                              insertTicks: true,
                                                              minorHeight: 8,
                                                              majorHeight: 16,
                                                              minorTicks: 4,
                                                              tickEndings: [0, 1],
                                                              defaultDistance: 0.1,
                                                              strokeOpacity: 1,
                                                              strokeWidth: 3,
                                                              strokeColor: 'black',
                                                              visible: 'inherit'
                                                          },
                                                          label: {
                                                              position: 'top',
                                                              offset:[0, 15],
                                                              fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'
                                                          }
  }); */
//angle
brd1.create('angle', [pt0, p_R, p_P],{name:'90^o', highlight:false, strokeWidth:1, strokeColor:'black', fillColor:'white', label:{visible:false, offset:[0, -10]}});
  //Texts
  /*var subtitle = brd1.create('text', [7, 13, function(){return 'Given RQ = ' + (seg_qr.L()).toFixed(2) + ' ,  can you find the length of the tangent RP ?'}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
*/
  var txt1 = brd1.create('text', [4, ty+3, 'Triangle ORP is a Right-Angled Triangle'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
  var txt2 = brd1.create('text', [4,ty + 1,  'Pythagorean Theorem: OP^2 = OR^2 + PR^2'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
  brd1.create('text', [4, ty+2, 'OR = Height | PR = Base | OP = Hypotenuse'],{display:'internal', visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
  //var txt3 = brd1.create('text', [4.0,ty, ''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  brd1.create('text',[4.0,ty,function(){return 'PR = &radic;(OP^2 - OR^2) =&radic;(' +(seg_op.L()).toFixed(2)+ '^2 - ' + rad[i] + '^2) = '+(seg_pr.L()).toFixed(2);}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
},
}
export default Boxes;
